import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
    // data
    state: () => ({
        drawer: false,
        serverDate: [parseInt(serverDate_year),parseInt(serverDate_month),parseInt(serverDate_day)],
    }),
    // computed properties
    getters: {
        getDrawer: (state) => state.drawer,
        getServerDate: (state) => state.serverDate,
    },
    // methods
    actions: {
        toggleDrawer() {
            this.drawer = !this.drawer;
        },
    },
})
