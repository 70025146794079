import {createApp} from 'vue';
import { createPinia } from 'pinia'
import {createWebHistory, createRouter} from 'vue-router';


// import app
import App from './NagyretitoApp.vue';
import '../myapp/myapp.scss';

// import pages
const Home = () => import('./pages/Home.vue');
const Information = () => import('./pages/Information.vue');
const Rules = () => import('./pages/Rules.vue');
const Fishes = () => import('./pages/Fishes.vue');
const Reservation = () => import('./pages/Reservation.vue');
const Contact = () => import('./pages/Contact.vue');


const routes = [
    {path: "/", name: "Kezdőlap", component: Home,},
    {path: "/informacio", name: "Információ", component: Information,},
    {path: "/szabalyzat", name: "Szabályzat", component: Rules,},
    {path: "/halak", name: "Halak", component: Fishes,},
    {path: "/foglalas", name: "Foglalás", component: Reservation,},
    {path: "/kapcsolat", name: "Kapcsolat", component: Contact,},
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to,from,savedPosition) {
        // if (savedPosition) {return savedPosition} else {return { x: 0, y: 0 }}
        return { top: 0 }
    },
  });

const pinia = createPinia()
const nagyretitoApp = createApp(App);

nagyretitoApp.use(router);
nagyretitoApp.use(pinia);

nagyretitoApp.mount('body');
