<template>
    <div id="appbar">
        <div id="appbar-title">
            <router-link to="/"><svg width="110" height="38" aria-label="Homepage link"><use href="nagyretito-icons.svg#nagyretito"></use></svg></router-link>
        </div>
        <div id="appbar-hamburger">
            <button @click="appStore.toggleDrawer()" class="button icon-button" aria-label="Hamburger button">
                <svg width="30" height="30"><use href="nagyretito-icons.svg#hamburger"></use></svg>
            </button>
        </div>
        <nav id="appbar-nav"
             :data-home="$route.name === 'Kezdőlap'? true:''"
             :data-information="$route.name === 'Információ'? true:''"
             :data-rules="$route.name === 'Szabályzat'? true:''"
             :data-fishes="$route.name === 'Halak'? true:''"
             :data-booking="$route.name === 'Foglalás'? true:''"
             :data-contact="$route.name === 'Kapcsolat'? true:''">
            <router-link to="/">Kezdőlap</router-link>
            <router-link to="/informacio">Információ</router-link>
            <router-link to="/szabalyzat">Szabályzat</router-link>
            <router-link to="/halak">Halak</router-link>
            <router-link to="/foglalas">Foglalás</router-link>
            <router-link to="/kapcsolat">Kapcsolat</router-link>
            <div id="vonal"></div>
        </nav>
        <div id="appbar-user">USER</div>
    </div>
    <div id="drawer" v-bind:class="{ active: appStore.getDrawer }">
        <div id="drawer-header">
            <svg width="120" height="40"><use href="nagyretito-icons.svg#nagyretito"></use></svg>
            <button type="button" class="button icon-button" @click="appStore.toggleDrawer()" aria-label="hamburger button">
                <svg width="24" height="24"><use href="nagyretito-icons.svg#close"></use></svg>
            </button>
        </div>
        <nav id="drawer-nav">
            <router-link to="/" @click="appStore.toggleDrawer()">Kezdőlap</router-link>
            <router-link to="/informacio" @click="appStore.toggleDrawer()">Információ</router-link>
            <router-link to="/szabalyzat" @click="appStore.toggleDrawer()">Szabályzat</router-link>
            <router-link to="/halak" @click="appStore.toggleDrawer()">Halak</router-link>
            <router-link to="/foglalas" @click="appStore.toggleDrawer()">Foglalás</router-link>
            <router-link to="/kapcsolat" @click="appStore.toggleDrawer()">Kapcsolat</router-link>
        </nav>
    </div>
    <router-view class="view" v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component"></component>
        </transition>
    </router-view>
</template>


<script setup>
import { useRouter } from "vue-router";
import {useAppStore} from "./stores/nagyretitoStores.js";



const router = useRouter();
const appStore = useAppStore();
</script>
